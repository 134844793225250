<template>
    <div v-if="config.show != 0"
        style="position: fixed;left: 0;top:0;z-index: 10000;background-color: rgba(0,0,0,0.3);width: 100vw;height: 100vh;transition: background-color .3s cubic-bezier(0.1,0.9,0.2,1);display: flex;align-items: center;justify-content: center;"
        :style="{
            backgroundColor: config.show <= 1 ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,0.3)'
        }">
        <div :style="{
            opacity: config.show <= 1 ? '0' : '1',
            scale: config.show <= 1 ? '1.1' : '1',
            zoom: zoom,
            minWidth: '600px',
            maxWidth: `calc(${100}vw - ${40}px)`,
        }"
            style="padding: 20px 20px;position: relative;display: inline-block;background-color: rgba(255,255,255,1);border-radius: 10px;box-shadow: 0 10px 30px rgba(0,0,0,0.5);max-height: calc(100vh - 40px);height: auto;overflow-y: auto;transition-property: opacity, scale;transition-duration: .3s; transition-timing-function: cubic-bezier(0.1,0.9,0.2,1);backdrop-filter: blur(20px);">
            <div style="width: 100%;height: 24px;">
                <div style="line-height: 24px;display: inline-block;font-size: 18px;font-family: 微软雅黑;color: #2e4232;">
                    {{ config.title }}</div>
                <div style="display: inline-block;height: 100%;float: right;">
                    <div style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: right;">
                        <svg @click="() => {
                            if (config.cancleCallBack != undefined) {
                                config.cancleCallBack()
                            }
                            config.control(false)
                        }" v-if="config.callBack != undefined && config.showCancle == true" style="float: right;"
                            width="24" height="24" viewBox="0 0 48 48" fill="currentColor">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M23.886 20.957L37.32 7.522a1 1 0 011.414 0l1.414 1.414a1 1 0 010 1.415L26.714 23.786 40.149 37.22a1 1 0 010 1.414l-1.414 1.414a1 1 0 01-1.414 0L23.886 26.614 10.45 40.049a1 1 0 01-1.415 0l-1.414-1.414a1 1 0 010-1.414l13.435-13.435L7.622 10.35a1 1 0 010-1.415l1.414-1.414a1 1 0 011.415 0l13.435 13.435z"
                                fill="currentColor" />
                        </svg>
                    </div>
                </div>
            </div>
            <div style="width: 100%;margin-top: 24px;line-height: 1.5;word-break: break-all;font-size: 16px;color: #2e4232;font-family: 微软雅黑"
                v-html="config.text">

            </div>
            <div
                style="width: 100%;margin-top: 36px;display: flex;align-items: center;justify-content: center;gap: 8px;">
                <!-- <div @click="() => {
                    if (config.cancleCallBack != undefined) {
                        config.cancleCallBack()
                    }
                    config.control(false)
                }" v-if="config.callBack != undefined && config.showCancle == true" class="button"
                    style="margin-right: 10px;">
                    {{ config.cancleText }}
                </div>
                <div @click="() => {
                    if (config.callBack != undefined) {
                        config.callBack()
                    }
                    config.control(false)
                }" class="button-blue" style="background-color: #24c27d;">
                    {{ config.okText }}
                </div> -->

                <yButton class="activeScale" v-if="config.callBack != undefined && config.showCancle == true" @click="() => {
                    if (config.cancleCallBack != undefined) {
                        config.cancleCallBack()
                    }
                    config.control(false)
                }" style="width: 100%;">{{ config.cancleText }}</yButton>
                <yButton class="activeScale" @click="() => {
                    if (config.callBack != undefined) {
                        config.callBack()

                    }
                    config.control(false)
                }" color="white" background-color="#24c27d" mask-color="white" style="width: 100%;">{{ config.okText }}
                </yButton>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, reactive, defineProps, computed, watch, onMounted, onBeforeUnmount } from 'vue'
import store from '../module/store'
import tool from '../module/tool'
import yButton from './yButton.vue';

const config = ref({
    show: 0,
    text: '',
    callBack: () => { },
    cancleCallBack: () => { },
    showCancle: true,
    okText: '',
    cancleText: '',
    title: '提示',
    async control(op, text = '', callBack = undefined, cancleCallBack = undefined, showCancle = true, okText = '确定', cancleText = '取消', title = '提示') {
        if (op) {
            this.text = text
            this.callBack = callBack
            this.cancleCallBack = cancleCallBack
            this.showCancle = showCancle
            this.okText = okText
            this.cancleText = cancleText
            this.title = title
            this.show = 1
            await tool.wait(0.001)
            this.show = 2
        } else {
            this.show = 1
            await tool.wait(0.2)
            this.show = 0
        }
    }
})

const zoom = ref(tool.pageZoom())

onMounted(async () => {
    store.onChange('controlWarningForm', () => {
        let data = store.getItem('controlWarningForm')
        config.value.control(data.op, data.text, data.callBack, data.cancleCallBack, data.showCancle, data.okText, data.cancleText, data.title)
    })

    tool.watchResize('fullWindow', () => {
        zoom.value = tool.pageZoom()
    })
})
</script>

<style scoped></style>