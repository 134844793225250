<template>
    <div v-if="config.show"
        style="width: 100vw;height: 100vh;position: fixed;left: 0;top: 0;z-index: 9000;background-color: rgba(0,0,0,0.3);transition-property: opacity, scale;transition-timing-function: cubic-bezier(0.1,0.9,0.2,1);transition-duration: .3s;display: flex;align-items: center;justify-content: center;"
        :style="{
            opacity: config.show > 1 ? 1 : 0,
            scale: config.show == 2 ? '1' : '1.1',
        }">
        <div style="width: 100vw;height: 100vh;position: absolute;left: 0;top: 0;"
            @click="tool.controlChangeRegionForm(0)">

        </div>
        <!-- 中间窗口 -->
        <div style="width: calc(100vw - 40px);height: calc(100vh - 40px);max-width: 500px;max-height: 500px;background-color: white;box-shadow:  0 10px 30px rgba(0,0,0,0.5);border-radius: 8px;position: relative;display: inline-block;overflow: hidden;"
            :style="{
                zoom: tool.pageZoom()
            }">
            <div style="width: 100%;height: 60px;position: relative;padding: 16px;">
                <!-- 关闭按钮 -->
                <div style="display: inline-block;float: right;height: 100%;">
                    <div
                        style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: right;font-family: almm;font-weight: 700;">
                        <svg @click="tool.controlChangeRegionForm(0)" style="float: right;" width="20" height="20"
                            viewBox="0 0 48 48" fill="currentColor">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M23.886 20.957L37.32 7.522a1 1 0 011.414 0l1.414 1.414a1 1 0 010 1.415L26.714 23.786 40.149 37.22a1 1 0 010 1.414l-1.414 1.414a1 1 0 01-1.414 0L23.886 26.614 10.45 40.049a1 1 0 01-1.415 0l-1.414-1.414a1 1 0 010-1.414l13.435-13.435L7.622 10.35a1 1 0 010-1.415l1.414-1.414a1 1 0 011.415 0l13.435 13.435z"
                                fill="currentColor" />
                        </svg>
                    </div>
                </div>
                <!-- 标题 -->
                <div style="display: inline-block;float: left;height: 100%;">
                    <div
                        style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: left;font-family: 微软雅黑;font-size: 18px;">
                        {{ config.isSelect ? '选择地区' : '切换地区' }}
                    </div>
                </div>
            </div>
            <!-- 内容区 -->
            <div style="width: 100%;height: 40px;display: flex;align-items: center;justify-content: center;">
                <input v-model="config.input" placeholder="地区名或代码" type="text"
                    style="outline: none;background-color: #f6f8f7;border-radius: 8px;padding: 8px 8px;font-family: 微软雅黑;font-size: 16px;margin-top: 0px;width: calc(100% - 32px);border: 0;">
            </div>
            <div
                style="width: 100%;height: calc(100% - 60px - 40px);overflow-y: auto;padding: 8px 16px;padding-right: 11px;">

                <div v-for="(item, index) in config.getDisplayList()"
                    style="width: 100%;display: flex;align-items: center;justify-content: center;height: 45px;gap: 8px;margin-bottom: 8px;">
                    <div v-for="(subItem, index2) in item"
                        @click="config.handleChangeRegion(subItem.regionCode, subItem.name)"
                        style="display: inline-block;background-color: #F6F8F7;border-radius: 8px;width: 100%;height: 45px;">
                        <div
                            style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;font-family: 微软雅黑;color: #2E4232;font-size: 16px;padding: 8px;word-break: break-all;text-align: center;">
                            {{ subItem.name }}
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script setup>
import router from '@/router'
import { ref, reactive, defineProps, computed, watch, onMounted, onBeforeUnmount } from 'vue'
import store from '../module/store'
import tool from '../module/tool'

const config = ref({
    show: 0,
    async control(op) {
        if (op) {
            this.get()
            this.show = 1
            await tool.wait(0.001)
            this.show = 2
        } else {
            this.show = 1
            await tool.wait(0.3)
            this.show = 0
        }
    },
    list: [],
    userType: '',
    input: '',
    isSelect: false,
    async get() {
        this.list = []
        let re = await tool.requestData('/get_region', {}, 'get')
        if (re.status) {
            this.list = re.data
            this.input = ''
        } else {
            tool.controlWarningForm(true, re.content, () => {
                tool.controlChangeRegionForm(false)
            }, undefined, false)
        }
    },
    screen(string) {
        let re = []
        for (let i = 0; i < this.list.length; i++) {
            if (this.list[i].regionCode.toString().indexOf(string) != -1 || this.list[i].name.indexOf(string) != -1) {
                re.push(this.list[i])
            }
        }
        return re
    },
    getDisplayList() {
        let re = [[]]
        let t = this.screen(this.input)
        for (let i = 0; i < t.length; i++) {
            if (re[re.length - 1].length < 3) {
                re[re.length - 1].push(t[i])
            } else {
                re.push([t[i]])
            }
        }
        return re
    },
    async handleChangeRegion(regionCode, regionName) {
        if (config.value.isSelect) {
            for (let i = 0; i < config.value.list.length; i++) {
                if (config.value.list[i].regionCode == regionCode) {
                    try {
                        config.value.selectCallBack(config.value.list[i])
                        config.value.control(false)
                    } catch (error) {

                    }
                    return
                }
            }
            config.value.control(false)
            return undefined
        }
        let re = await tool.requestData('/' + this.userType + '/change_region', {
            regionCode: regionCode
        })
        if (re.status) {
            this.control(false)
            let t = store.getItem('userData')
            t.regionName = regionName
            store.setItem('userData', t)
            if (config.value.selectCallBack == undefined) {
                tool.controlWarningForm(true, `当前登录的教练用户的地区已切换为${regionName}。`)
            } else {
                try {
                    config.value.selectCallBack()
                } catch (error) {

                }
            }
        } else {
            tool.controlWarningForm(true, re.content)
        }
    },
    selectCallBack: () => { }
})

onMounted(async () => {
    store.onChange('controlChangeRegionForm', async () => {
        let data = store.getItem('controlChangeRegionForm')
        config.value.userType = data.userType
        config.value.isSelect = false
        config.value.selectCallBack = data.callBack
        config.value.control(data.op)
    })
    store.onChange('controlSelectRegionForm', async () => {
        let data = store.getItem('controlSelectRegionForm')
        config.value.isSelect = true
        config.value.selectCallBack = data.callBack
        config.value.control(data.op)
    })
})
</script>

<style scoped></style>