<template>
    <div :title="title ? title : ''" style="display: inline-block;overflow: hidden;" :style="{
        backgroundColor: backgroundColor,
        borderRadius: borderRadius,
        height: height,
        width: width,
        opacity: disable ? 0.6 : 1
    }">
        <div :class="disable ? '' : (maskColor == 'black' ? 'buttonMask' : 'buttonMask2')"
            style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;font-family: 微软雅黑;word-break: keep-all;"
            :style="{
                color: color,
                fontSize: fontSize,
                padding: padding,
                justifyContent: textAlign,
                userSelect: userSelect
            }">
            <slot></slot>
        </div>
    </div>
</template>

<script setup>
import { ref, reactive, defineProps, computed, watch, onMounted, onBeforeUnmount } from 'vue'

const props = defineProps({
    color: {
        type: String,
        default: 'black'
    },
    backgroundColor: {
        type: String,
        default: 'rgba(0,0,0,0)'
    },
    padding: {
        type: String,
        default: '8px 16px'
    },
    fontSize: {
        type: String,
        default: '16px'
    },
    maskColor: {
        type: String,
        default: 'black'
    },
    borderRadius: {
        type: String,
        default: '3px'
    },
    width: {
        type: String,
        default: 'unset'
    },
    height: {
        type: String,
        default: 'unset'
    },
    textAlign: {
        type: String,
        default: 'center'
    },
    disable: {
        type: Boolean,
        default: false
    },
    userSelect: {
        type: String,
        default: 'none'
    },
    title: {
        type: String,
        default: ''
    }
})

onMounted(async () => {

})
</script>

<style scoped>
.buttonMask {
    background-color: transparent;
}

.buttonMask:hover {
    background-color: rgba(0, 0, 0, 0.2);
}

.buttonMask:active {
    background-color: rgba(0, 0, 0, 0.1);
}

.buttonMask2 {
    background-color: transparent;
}

.buttonMask2:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

.buttonMask2:active {
    background-color: rgba(255, 255, 255, 0.1);
}
</style>