<template>
    <div id="tipFormBackground" v-if="config.show" @mousemove="position.handleMove" @mouseup="position.handleEnd"
        @mouseleave="position.handleEnd"
        style="width: 100vw;height: 100vh;position: fixed;left: 0;top: 0;z-index: 9000;background-color: rgba(0,0,0,0);transition-property: opacity, scale;transition-timing-function: cubic-bezier(0.1,0.9,0.2,1);transition-duration: .3s;display: flex;align-items: center;justify-content: center;"
        :style="{
            opacity: config.show > 1 ? 1 : 0,
            pointerEvents: position.isMoveing ? 'unset' : 'none'
        }">
        <div v-show="config.isGF || config.closeButtonStatus"
            style="width: 100vw;height: 100vh;position: absolute;left: 0;top: 0;"
            @click="tool.controlTipForm(0); tool.controlAudio(false)">

        </div>
        <!-- 中间窗口 -->
        <div id="tipFormMain" @mousedown="position.handleStart" style="
            width: calc(100% - 40px);
            max-width: 600px;
            text-align: center;
            position: relative;
            transition: scale .3s cubic-bezier(0.1,0.9,0.2,1);
            overflow: visible;pointer-events:visible;
            " :style="{
                zoom: zoom,
                transform: `translate(${position.x / zoom}px,${position.y / zoom}px)`,
            }">
            <div style="width: 100%;height: 100%;position: relative;transition: scale .3s cubic-bezier(0.1,0.9,0.2,1);display: inline-block;
            padding: 5px 0px;
            background-color: #fff;
            border-radius: 8px;
            width: 100%;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            border: 1px solid #007bff;" :style="{

                scale: config.show == 2 ? '1' : '1.1',
            }">
                <div style="width: 100%;height: 40px;position: relative;padding: 0px;cursor: move;">
                    <!-- 关闭按钮 -->
                    <div v-show="config.isGF || config.closeButtonStatus"
                        style="display: inline-block;float: right;height: 100%;position: absolute;right: 16px;top: 0px;">
                        <div
                            style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: right;font-family: almm;font-weight: 700;">
                            <div style="display: inline-block;cursor: default;">
                                <svg @click="tool.controlTipForm(0); tool.controlAudio(false)"
                                    style="float: right;cursor: unset !important;" width="20" height="20"
                                    viewBox="0 0 48 48" fill="currentColor">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M23.886 20.957L37.32 7.522a1 1 0 011.414 0l1.414 1.414a1 1 0 010 1.415L26.714 23.786 40.149 37.22a1 1 0 010 1.414l-1.414 1.414a1 1 0 01-1.414 0L23.886 26.614 10.45 40.049a1 1 0 01-1.415 0l-1.414-1.414a1 1 0 010-1.414l13.435-13.435L7.622 10.35a1 1 0 010-1.415l1.414-1.414a1 1 0 011.415 0l13.435 13.435z"
                                        fill="currentColor" />
                                </svg>
                            </div>

                        </div>
                    </div>
                    <!-- 标题 -->
                    <div style="display: inline-block;height: 100%;width: 100%;">
                        <h3
                            style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;font-family: 微软雅黑;font-size: 24px;text-align: center;color: #333;">
                            {{ config.isGF ? '官方解释' : '技巧讲解' }}
                        </h3>
                    </div>
                </div>
                <!-- 内容区 -->
                <div style="width: 100%;height: auto;padding: 12px 16px;word-break: break-all;overflow-y: auto;" :style="{
                    maxHeight: `calc(100vh / ${zoom} - 120px)`
                }">

                    <div v-if="config.question != undefined && (config.isGF ? config.question.guanfangjieshi : config.question.jiqiaojieshi) != undefined"
                        style="display: inline-block;width: 100%" :style="{
                            zoom: config.fontZoom
                        }">
                        <span style="font-family: 微软雅黑;"
                            v-for="(item, index) in (config.isGF ? config.question.guanfangjieshi : config.question.jiqiaojieshi)"
                            :style="{
                                color: item.flag ? (config.textColor) : 'black',
                                // fontWeight: (item.flag) ? 'bold' : 'unset'
                            }">{{ item.text }}</span>
                    </div>
                    <div v-if="config.isGF == false && config.img"
                        style="text-align: center;width: 100%;margin-top: 4px;">
                        <img @click="() => {
                            tool.controlImagePreviewForm(1, config.img)
                        }" v-if="config.img !== undefined"
                            style="width: 100%;border: 1px solid lightgrey;margin-top: 10px;margin: 0 auto;border-radius: 5px"
                            :src="config.img" alt="">
                    </div>


                </div>
                <!-- 控制按钮 -->
                <div
                    style="width: 100%;display: flex;align-items: center;justify-content: center;gap: 8px;height: 60px;">
                    <yButton background-color="#007bff" color="white" mask-color="white" v-if="config.isGF == false"
                        @click="handleReplay">重听</yButton>
                    <yButton :disable="config.closeButtonStatus == false" background-color="#007bff" color="white"
                        mask-color="white" @click="() => {
                            if (config.closeButtonStatus == false) return
                            tool.controlTipForm(false); tool.controlAudio(false)
                        }">关闭</yButton>
                </div>
            </div>

        </div>
    </div>
</template>

<script setup>
import router from '@/router'
import { ref, reactive, defineProps, computed, watch, onMounted, onBeforeUnmount } from 'vue'
import store from '../module/store'
import tool from '../module/tool'
import md5 from 'md5'
import yButton from './yButton.vue'



const position = reactive({
    x: 0,
    y: 0,
    init() {
        let re = tool.localStorage.getItem('tipFormPosition')
        if (re == undefined) {
            tool.localStorage.setItem('tipFormPosition', JSON.stringify({
                x: 0,
                y: 0
            }))
        } else {
            try {
                re = JSON.parse(re)
                this.x = re.x
                this.y = re.y
                this.modify()
                tool.localStorage.setItem('tipFormPosition', JSON.stringify({
                    x: this.x,
                    y: this.y
                }))
            } catch (error) {
                tool.localStorage.setItem('tipFormPosition', JSON.stringify({
                    x: 0,
                    y: 0
                }))
            }
        }
    },
    isMoveing: false,
    handleStart(e) {
        this.isMoveing = true
        this.cx = this.sx = e.x
        this.cy = this.sy = e.y
    },
    cx: 0,
    cy: 0,
    sx: 0,
    sy: 0,
    handleMove(e) {
        if (this.isMoveing) {
            this.x += e.x - this.cx
            this.y += e.y - this.cy
            this.cx = e.x
            this.cy = e.y
        }
    },
    handleEnd(e) {
        this.isMoveing = false
        this.modify()
        tool.localStorage.setItem('tipFormPosition', JSON.stringify({
            x: this.x,
            y: this.y
        }))
    },
    modify() {
        let re = tool.getDistanceToParent('tipFormMain', 'tipFormBackground')
        if (re.left < 0) {
            this.x = (document.getElementById('tipFormBackground').clientWidth / 2 - document.getElementById('tipFormMain').clientWidth / 2 * zoom.value) * -1
        }
        if (re.top < 0) {
            this.y = (document.getElementById('tipFormBackground').clientHeight / 2 - document.getElementById('tipFormMain').clientHeight / 2 * zoom.value) * -1
        }
        if (document.getElementById('tipFormBackground').clientWidth < (re.left + document.getElementById('tipFormMain').clientWidth * zoom.value)) {
            this.x = (document.getElementById('tipFormBackground').clientWidth / 2 - document.getElementById('tipFormMain').clientWidth / 2 * zoom.value)
        }
        if (document.getElementById('tipFormBackground').clientHeight < (re.top + document.getElementById('tipFormMain').clientHeight * zoom.value)) {
            this.y = (document.getElementById('tipFormBackground').clientHeight / 2 - document.getElementById('tipFormMain').clientHeight / 2 * zoom.value)
        }
    }
})

const config = ref({
    fontZoom: 1,
    show: 0,
    question: undefined,
    isGF: false,
    img: undefined,
    closeButtonStatus: false,
    count: 1,
    async control(op) {
        if (op) {
            this.show = 1
            await tool.wait(0.001)
            this.show = 2
            position.init()
            if (config.value.isGF) {
                config.value.closeButtonStatus = true
            } else {
                config.value.closeButtonStatus = true
                setTimeout(async () => {
                    config.value.closeButtonStatus = true

                    // let c = 0
                    // while (1) {
                    //     if (config.value.show == 0) return
                    //     await tool.wait(0.1)
                    //     c += 1
                    //     if (c == 50) {
                    //         config.value.control(false)
                    //         return
                    //     }
                    // }
                }, 5000);
            }

        } else {
            this.show = 1
            await tool.wait(0.3)
            this.show = 0
        }
    },
    textColor: 'red',
    async handleChangeTextColor() {
        while (1) {
            await tool.wait(0.5)
            if (this.textColor == 'red') {
                this.textColor = 'gray'
            } else {
                this.textColor = 'red'
            }
        }
    },
})

const handleReplay = () => {
    tool.controlAudio(1, undefined, () => { })
}

const zoom = ref(tool.pageZoom())

onMounted(async () => {
    store.onChange('controlTipForm', async () => {
        let data = store.getItem('controlTipForm')
        if (data.op) {
            config.value.question = data.question
            config.value.isGF = data.isGF
            config.value.img = data.img
            config.value.fontZoom = data.fontZoom
        }
        config.value.control(data.op)
    })

    store.onChange('pageZoom', () => {
        zoom.value = tool.pageZoom()
    })

    config.value.handleChangeTextColor()
})
</script>

<style scoped></style>