<template>
  <div v-show="config.show"
    style="width: 100vw;height: 100vh;position: fixed;left: 0;top: 0;z-index: 10010;display: flex;align-items: center;justify-content: center;">
    <div v-show="config.visible"
      style="width:150px;height:180px;display: inline-block;border-radius: 10px;background-color: rgba(0,0,0,0.8)"
      :style="{
        zoom: tool.pageZoom()
      }">
      <div
        style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;flex-direction: column">
        <loading-circle></loading-circle>
        <div style="color: white;font-family: 微软雅黑;font-size: 18px;margin-top: 20px">{{ config.text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, defineProps, computed, watch, onMounted, onBeforeUnmount } from 'vue'
import loadingCircle from './loadingCircle.vue'
import store from '../module/store'
import tool from '@/module/tool';

const config = ref({
  show: false,
  text: '请稍候',
  visible: false,
  control(op, visible = true) {
    if (op) {
      this.show = true
      this.visible = (visible == true)
    } else {
      this.show = false
    }
  }
})

onMounted(async () => {
  store.onChange('controlLoading', () => {
    let data = store.getItem('controlLoading')
    config.value.text = data.text == undefined ? '请稍候' : data.text
    config.value.control(data.op == true, data.visible == undefined ? true : data.visible)
  })
})
</script>

<style scoped></style>