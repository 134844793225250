<template>
    <div v-if="config.show != 0"
        style="position: fixed;left: 0;top:0;z-index: 9990;background-color: rgba(0,0,0,0.3);width: 100vw;height: 100vh;transition: background-color .3s cubic-bezier(0.1,0.9,0.2,1);display: flex;align-items: center;justify-content: center;"
        :style="{
            backgroundColor: config.show <= 1 ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,0.3)'
        }">
        <div :style="{
            opacity: config.show <= 1 ? '0' : '1',
            scale: config.show <= 1 ? '1.1' : '1',
            zoom: zoom
        }"
            style="padding: 20px 20px;position: relative;display: inline-block;background-color: rgba(255,255,255,1);border-radius: 10px;box-shadow: 0 10px 30px rgba(0,0,0,0.5);max-width: calc(100% - 40px);max-height: calc(100vh - 40px);width: 800px;height: 620px;transition-property: opacity, scale;transition-duration: .3s; transition-timing-function: cubic-bezier(0.1,0.9,0.2,1);backdrop-filter: blur(20px);">
            <div style="width: 100%;height: 24px;">
                <div style="line-height: 24px;display: inline-block;font-size: 18px;font-family: 微软雅黑;color: #2e4232;">
                    设置题号
                </div>
                <div style="display: inline-block;height: 100%;float: right;">
                    <div style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: right;">
                        <svg @click="() => {
                            config.control(false)
                        }" style="float: right;" width="24" height="24" viewBox="0 0 48 48" fill="currentColor">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M23.886 20.957L37.32 7.522a1 1 0 011.414 0l1.414 1.414a1 1 0 010 1.415L26.714 23.786 40.149 37.22a1 1 0 010 1.414l-1.414 1.414a1 1 0 01-1.414 0L23.886 26.614 10.45 40.049a1 1 0 01-1.415 0l-1.414-1.414a1 1 0 010-1.414l13.435-13.435L7.622 10.35a1 1 0 010-1.415l1.414-1.414a1 1 0 011.415 0l13.435 13.435z"
                                fill="currentColor" />
                        </svg>
                    </div>
                </div>
            </div>
            <div
                style="width: 100%;height: calc(100% - 24px - 16px - 20px);margin-top: 16px;position: relative;display: flex;align-items: center;justify-content: center;gap: 8px;">
                <div style="width: 540px;height: 100%;overflow-y: auto;">

                    <div
                        style="width: 100%;display: flex;align-items: flex-start;justify-content: left;gap: 8px;flex-direction: column;">
                        <yButton :background-color="config.currentListIndex == index ? '#24c27d' : '#fff'" @click="async () => {
                            config.showRight = false
                            config.currentListIndex = index
                            await tool.wait(0.01)
                            config.showRight = true
                        }" v-for="(item, index) in config.thirdList" border-radius="8px" style="width: 100%;"
                            padding="8px" :mask-color="config.currentListIndex == index ? 'white' : 'black'">
                            <div
                                style="width: 100%;display: flex;align-items: flex-start;justify-content: left;flex-direction: column;gap: 3px;">
                                <div
                                    style="width: 100%;display: flex;align-items: center;justify-content: left;gap: 8px;">

                                    <div v-if="!item.iconSetted && config.currentListIndex == index"
                                        style="width: 18px;height: 18px;border-radius: 100px;border: 1px solid white;">
                                        <div
                                            style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;color: white;font-family: 微软雅黑;font-size: 10px;font-weight: 700;">
                                            {{ item.icon }}
                                        </div>
                                    </div>

                                    <div v-if="item.iconSetted && config.currentListIndex == index"
                                        style="width: 18px;height: 18px;border-radius: 100px;border: 1px solid #fff;background-color: #fff;">
                                        <div
                                            style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;color: #24c27d;font-family: 微软雅黑;font-size: 10px;font-weight: 700;">
                                            {{ item.icon }}
                                        </div>
                                    </div>

                                    <div v-if="!item.iconSetted && config.currentListIndex != index"
                                        style="width: 18px;height: 18px;border-radius: 100px;border: 1px solid #2e4232;">
                                        <div
                                            style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;color: #2e4232;font-family: 微软雅黑;font-size: 10px;font-weight: 700;">
                                            {{ item.icon }}
                                        </div>
                                    </div>

                                    <div v-if="item.iconSetted && config.currentListIndex != index"
                                        style="width: 18px;height: 18px;border-radius: 100px;border: 1px solid #2e4232;background-color: #2e4232;">
                                        <div
                                            style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;color: white;font-family: 微软雅黑;font-size: 10px;font-weight: 700;">
                                            {{ item.icon }}
                                        </div>
                                    </div>

                                    <div style="font-family: 微软雅黑;font-size: 18px;color: #2e4232;" :style="{
                                        color: config.currentListIndex == index ? 'white' : '#2e4232'
                                    }">
                                        {{ item.name }}
                                    </div>
                                </div>
                                <div style="font-family: 微软雅黑;font-size: 14px;color: gray;" :style="{
                                    color: config.currentListIndex == index ? 'white' : 'gray'
                                }">
                                    位于 {{ item.location }}
                                </div>
                            </div>
                        </yButton>
                    </div>

                </div>
                <div style="width: 100%;height: 100%;">
                    <div
                        style="width: 100%;height: calc(100% - 45px - 8px);border-radius: 8px;background-color: #f6f8f7;overflow-y: auto;padding-bottom: 16px;">
                        <div v-if="config.showRight"
                            style="width: 100%;height: 100%;display: flex;align-items: flex-start;justify-content: left;gap: 8px;padding: 16px;flex-direction: column;">

                            <div>
                                <yButton style="height: 25px"
                                    @click="config.thirdList[config.currentListIndex].children.disorder.show = !config.thirdList[config.currentListIndex].children.disorder.show"
                                    padding="3px" background-color="rgba(0,0,0,0)">
                                    <div style="display: flex;align-items: center;justify-content: left;gap: 3px;">
                                        <svg :style="{
                                            rotate: config.thirdList[config.currentListIndex].children.disorder.show ? '90deg' : ''
                                        }" width="12" height="12" viewBox="0 0 48 48" fill="currentColor">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M32.556 24L16.293 7.737a1 1 0 010-1.415l1.414-1.414a1 1 0 011.414 0L36.8 22.586a2 2 0 010 2.828L19.121 43.092a1 1 0 01-1.414 0l-1.414-1.414a1 1 0 010-1.414L32.556 24z"
                                                fill="currentColor" />
                                        </svg>
                                        <span style="font-size: 14px;font-family: 微软雅黑;color: #2e4232;">未设置题号{{
                                            `(${config.thirdList[config.currentListIndex].children.disorder.list.length}个)`
                                            }}</span>
                                    </div>
                                </yButton>
                            </div>

                            <div v-if="config.thirdList[config.currentListIndex].children.disorder.show"
                                style="display: flex;flex-direction: column;align-items: flex-start;justify-content: left;gap: 8px;">
                                <div v-if="config.thirdList[config.currentListIndex].children.disorder.list.length == 0"
                                    style="width: 100%;font-size: 14px;color: gray;font-family: 微软雅黑;">
                                    空
                                </div>
                                <div v-for="(item, index) in config.thirdList[config.currentListIndex].children.disorder.list"
                                    style="display: flex;align-items: center;justify-content: left;gap: 8px;border-radius: 8px;border: 1px solid #2e4232;width: 100%;">
                                    <div
                                        style="width: 100%;display: flex;flex-direction: column;align-items: flex-start;justify-content: left;padding: 8px;">
                                        <div style="font-family: 微软雅黑;font-size: 14px;color: #2e4232;">
                                            {{ item.tigan }}
                                        </div>
                                        <div
                                            v-if="config.getImportStatus(config.thirdList[config.currentListIndex].thirdId, item.id) != undefined">
                                            <span
                                                v-if="config.getImportStatus(config.thirdList[config.currentListIndex].thirdId, item.id).success == undefined"
                                                style="padding: 1px 3px;border-radius: 3px;background-color: rgba(255,0,0,0.1);font-family: 微软雅黑;font-size: 14px;color: red;">导入失败，该题已在当前三级类别中，题号为{{
                                                    config.getImportStatus(config.thirdList[config.currentListIndex].thirdId,
                                                        item.id).index }}</span>
                                            <span
                                                v-if="config.getImportStatus(config.thirdList[config.currentListIndex].thirdId, item.id).success"
                                                style="padding: 1px 3px;border-radius: 3px;background-color: rgba(0,255,0,0.1);font-family: 微软雅黑;font-size: 14px;color: #24c27d;">导入成功，题号为{{
                                                    config.getImportStatus(config.thirdList[config.currentListIndex].thirdId,
                                                        item.id).index }}</span>
                                        </div>
                                    </div>
                                    <div
                                        style="width: 28px;height: 100%;display: flex;align-items: center;justify-content: center;color: #2e4232;padding-right: 8px;">
                                        <yButton @click="config.handleDisorderMoreClick(index)"
                                            background-color="rgba(0,0,0,0)" padding="3px">
                                            <svg width="14" height="14" viewBox="0 0 48 48" fill="currentColor">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M35 21h6v6h-6v-6zm-22 0v6H7v-6h6zm14 0v6h-6v-6h6z"
                                                    fill="currentColor" />
                                            </svg>
                                        </yButton>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <yButton style="height: 25px"
                                    @click="config.thirdList[config.currentListIndex].children.order.show = !config.thirdList[config.currentListIndex].children.order.show"
                                    padding="3px" background-color="rgba(0,0,0,0)">
                                    <div style="display: flex;align-items: center;justify-content: left;gap: 3px;">
                                        <svg :style="{
                                            rotate: config.thirdList[config.currentListIndex].children.order.show ? '90deg' : ''
                                        }" width="12" height="12" viewBox="0 0 48 48" fill="currentColor">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M32.556 24L16.293 7.737a1 1 0 010-1.415l1.414-1.414a1 1 0 011.414 0L36.8 22.586a2 2 0 010 2.828L19.121 43.092a1 1 0 01-1.414 0l-1.414-1.414a1 1 0 010-1.414L32.556 24z"
                                                fill="currentColor" />
                                        </svg>
                                        <span style="font-size: 14px;font-family: 微软雅黑;color: #2e4232;">已设置题号{{
                                            `(${config.thirdList[config.currentListIndex].children.order.list.length}个)`
                                            }}</span>
                                    </div>
                                </yButton>
                            </div>

                            <div v-if="config.thirdList[config.currentListIndex].children.order.show"
                                style="display: flex;flex-direction: column;align-items: flex-start;justify-content: left;gap: 8px;">
                                <div v-if="config.thirdList[config.currentListIndex].children.order.list.length == 0"
                                    style="width: 100%;font-size: 14px;color: gray;font-family: 微软雅黑;">
                                    空
                                </div>
                                <div v-for="(item, index) in config.thirdList[config.currentListIndex].children.order.list"
                                    style="display: flex;align-items: center;justify-content: left;gap: 8px;border-radius: 8px;border: 1px solid #2e4232;width: 100%;">
                                    <div
                                        style="width: 30px;height: 100%;display: flex;align-items: center;justify-content: center;font-size: 14px;color: #2e4232;font-family: 微软雅黑;">
                                        {{ item._index }}.
                                    </div>
                                    <div
                                        style="width: 100%;display: flex;flex-direction: column;align-items: flex-start;justify-content: left;padding: 8px;">
                                        <div style="font-family: 微软雅黑;font-size: 14px;color: #2e4232;">
                                            {{ item.tigan }}
                                        </div>
                                        <div
                                            v-if="config.getImportStatus(config.thirdList[config.currentListIndex].thirdId, item.id) != undefined">
                                            <span
                                                v-if="config.getImportStatus(config.thirdList[config.currentListIndex].thirdId, item.id).success == undefined"
                                                style="padding: 1px 3px;border-radius: 3px;background-color: rgba(255,0,0,0.1);font-family: 微软雅黑;font-size: 14px;color: red;">导入失败，该题已在当前三级类别中，题号为{{
                                                    config.getImportStatus(config.thirdList[config.currentListIndex].thirdId,
                                                        item.id).index }}</span>
                                            <span
                                                v-if="config.getImportStatus(config.thirdList[config.currentListIndex].thirdId, item.id).success"
                                                style="padding: 1px 3px;border-radius: 3px;background-color: rgba(0,255,0,0.1);font-family: 微软雅黑;font-size: 14px;color: #24c27d;">导入成功，题号为{{
                                                    config.getImportStatus(config.thirdList[config.currentListIndex].thirdId,
                                                        item.id).index }}</span>
                                        </div>
                                    </div>
                                    <div
                                        style="width: 28px;height: 100%;display: flex;align-items: center;justify-content: center;color: #2e4232;padding-right: 8px;">
                                        <yButton @click="config.handleOrderMoreClick(index)"
                                            background-color="rgba(0,0,0,0)" padding="3px">
                                            <svg width="14" height="14" viewBox="0 0 48 48" fill="currentColor">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M35 21h6v6h-6v-6zm-22 0v6H7v-6h6zm14 0v6h-6v-6h6z"
                                                    fill="currentColor" />
                                            </svg>
                                        </yButton>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <yButton style="height: 25px"
                                    @click="config.thirdList[config.currentListIndex].children.delete.show = !config.thirdList[config.currentListIndex].children.delete.show"
                                    padding="3px" background-color="rgba(0,0,0,0)">
                                    <div style="display: flex;align-items: center;justify-content: left;gap: 3px;">
                                        <svg :style="{
                                            rotate: config.thirdList[config.currentListIndex].children.delete.show ? '90deg' : ''
                                        }" width="12" height="12" viewBox="0 0 48 48" fill="currentColor">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M32.556 24L16.293 7.737a1 1 0 010-1.415l1.414-1.414a1 1 0 011.414 0L36.8 22.586a2 2 0 010 2.828L19.121 43.092a1 1 0 01-1.414 0l-1.414-1.414a1 1 0 010-1.414L32.556 24z"
                                                fill="currentColor" />
                                        </svg>
                                        <span style="font-size: 14px;font-family: 微软雅黑;color: #2e4232;">已删除{{
                                            `(${config.thirdList[config.currentListIndex].children.delete.list.length}个)`
                                            }}</span>
                                    </div>
                                </yButton>
                            </div>

                            <div v-if="config.thirdList[config.currentListIndex].children.delete.show"
                                style="display: flex;flex-direction: column;align-items: flex-start;justify-content: left;gap: 8px;">
                                <div v-if="config.thirdList[config.currentListIndex].children.delete.list.length == 0"
                                    style="width: 100%;font-size: 14px;color: gray;font-family: 微软雅黑;">
                                    空
                                </div>
                                <div v-for="(item, index) in config.thirdList[config.currentListIndex].children.delete.list"
                                    style="display: flex;align-items: center;justify-content: left;gap: 8px;border-radius: 8px;border: 1px solid #2e4232;width: 100%;">
                                    <div
                                        style="width: 100%;display: flex;flex-direction: column;align-items: flex-start;justify-content: left;padding: 8px;">
                                        <div style="font-family: 微软雅黑;font-size: 14px;color: #2e4232;">
                                            {{ item.tigan }}
                                        </div>
                                    </div>
                                    <div
                                        style="width: 28px;height: 100%;display: flex;align-items: center;justify-content: center;color: #2e4232;padding-right: 8px;">
                                        <yButton @click="config.handleDeleteMoreClick(index)"
                                            background-color="rgba(0,0,0,0)" padding="3px">
                                            <svg width="14" height="14" viewBox="0 0 48 48" fill="currentColor">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M35 21h6v6h-6v-6zm-22 0v6H7v-6h6zm14 0v6h-6v-6h6z"
                                                    fill="currentColor" />
                                            </svg>
                                        </yButton>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                    <div
                        style="width: 100%;height: 45px;margin-top: 8px;display: flex;align-items: center;justify-content: center;gap: 8px;">
                        <yButton v-if="config.imported == false" @click="config.control(false)"
                            style="width: 100%;height: 100%;" border-radius="8px">取消
                        </yButton>
                        <yButton v-if="config.imported" @click="config.handleOK()" style="width: 100%;height: 100%;"
                            border-radius="8px" color="white" mask-color="white" background-color="#24c27d">确定</yButton>
                        <yButton v-if="config.imported == false" @click="config.handleImport()"
                            style="width: 100%;height: 100%;" border-radius="8px" color="white" mask-color="white"
                            background-color="#24c27d">导入</yButton>
                    </div>
                </div>
            </div>
            <div style="width: 100%;font-family: 微软雅黑;color: red;font-size: 14px;margin-top: 8px;">
                如果不需要给题目设置题号，以及取消向某个三级类别添加某个题目，请直接导入。
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, reactive, defineProps, computed, watch, onMounted, onBeforeUnmount } from 'vue'
import store from '../module/store'
import tool from '../module/tool'
import yButton from './yButton.vue';

const computeZoom = () => {
    let data = store.getItem('windowData')
    if (data.width < 1200) {
        zoom.value = data.width / 1200
    } else {
        zoom.value = 1
    }
}
const zoom = ref(1)

const config = ref({
    show: 0,
    type: 1,
    callBack: () => { },
    async control(op) {
        if (op) {
            computeZoom()
            this.show = 1
            await tool.wait(0.001)
            this.show = 2
        } else {
            this.show = 1
            await tool.wait(0.2)
            this.show = 0
        }
    },
    thirdList: [],
    currentListIndex: 0,
    showRight: true,
    imported: false,
    moveItem(index, dir) {
        if (dir) {
            let t = config.value.thirdList[config.value.currentListIndex].children.disorder.list[index - 1]
            config.value.thirdList[config.value.currentListIndex].children.disorder.list[index - 1] = config.value.thirdList[config.value.currentListIndex].children.disorder.list[index]
            config.value.thirdList[config.value.currentListIndex].children.disorder.list[index] = t
        } else {
            let t = config.value.thirdList[config.value.currentListIndex].children.disorder.list[index + 1]
            config.value.thirdList[config.value.currentListIndex].children.disorder.list[index + 1] = config.value.thirdList[config.value.currentListIndex].children.disorder.list[index]
            config.value.thirdList[config.value.currentListIndex].children.disorder.list[index] = t
        }
    },
    deleteItem(index, group) {
        let t = config.value.thirdList[config.value.currentListIndex].children[group].list[index]
        let re = []
        for (let i = 0; i < config.value.thirdList[config.value.currentListIndex].children[group].list.length; i++) {
            if (i == index) continue
            re.push(config.value.thirdList[config.value.currentListIndex].children[group].list[i])
        }
        config.value.thirdList[config.value.currentListIndex].children[group].list = re
        return t
    },
    handleDisorderMoreClick(index) {
        let menu = []
        if (index) {
            menu.push({
                name: '上移',
                callBack() {
                    config.value.moveItem(index, 1)
                }
            })
        }
        if (index != config.value.thirdList[config.value.currentListIndex].children.disorder.list.length - 1) {
            menu.push({
                name: '下移',
                callBack() {
                    config.value.moveItem(index, 0)
                }
            })
        }
        menu.push({
            name: '删除',
            callBack() {
                let t = config.value.deleteItem(index, 'disorder')
                config.value.thirdList[config.value.currentListIndex].children.delete.list.push(t)
            }
        })
        menu.push({
            name: '设置题号',
            callBack() {
                tool.controlInput(true, '', '请输入题号', (e) => {
                    e = parseInt(e)
                    if (isNaN(e)) {
                        tool.controlWarningForm(true, '题号应为数字')
                        return
                    }
                    if (e < 1) {
                        tool.controlWarningForm(true, '题号应为正整数')
                        return
                    }
                    for (let i = 0; i < config.value.thirdList[config.value.currentListIndex].children.order.list.length; i++) {
                        if (config.value.thirdList[config.value.currentListIndex].children.order.list[i]._index == e) {
                            tool.controlWarningForm(true, '此题号已存在')
                            return
                        }
                    }
                    let t = config.value.deleteItem(index, 'disorder')
                    t._index = e
                    config.value.thirdList[config.value.currentListIndex].children.order.list.push(t)
                    config.value.thirdList[config.value.currentListIndex].children.order.list.sort((a, b) => {
                        return a._index - b._index
                    })
                })
            }
        })
        tool.controlMenuForm(true, '操作', menu)
    },
    handleOrderMoreClick(index) {
        let menu = [
            {
                name: '去除题号',
                callBack() {
                    let t = config.value.deleteItem(index, 'order')
                    delete t._index
                    config.value.thirdList[config.value.currentListIndex].children.disorder.list.push(t)
                }
            },
            {
                name: '删除',
                callBack() {
                    let t = config.value.deleteItem(index, 'order')
                    delete t._index
                    config.value.thirdList[config.value.currentListIndex].children.delete.list.push(t)
                }
            }
        ]
        tool.controlMenuForm(true, '操作', menu)
    },
    handleDeleteMoreClick(index) {
        let menu = [
            {
                name: '恢复',
                callBack() {
                    let t = config.value.deleteItem(index, 'delete')
                    config.value.thirdList[config.value.currentListIndex].children.disorder.list.push(t)
                }
            }
        ]
        tool.controlMenuForm(true, '操作', menu)
    },
    getRe() {
        let re = []
        for (let i = 0; i < config.value.thirdList.length; i++) {
            for (let i1 = 0; i1 < config.value.thirdList[i].children.disorder.list.length; i1++) {
                re.push({
                    questionId: config.value.thirdList[i].children.disorder.list[i1].id,
                    thirdId: config.value.thirdList[i].thirdId
                })
            }
            for (let i1 = 0; i1 < config.value.thirdList[i].children.order.list.length; i1++) {
                re.push({
                    questionId: config.value.thirdList[i].children.order.list[i1].id,
                    thirdId: config.value.thirdList[i].thirdId,
                    index: config.value.thirdList[i].children.order.list[i1]._index
                })
            }
        }
        return re
    },
    async handleOK() {
        config.value.control(false)
    },
    async handleImport() {
        if (config.value.callBack != undefined) {
            try {
                config.value.control(false)
                config.value.callBack(config.value.getRe())
            } catch (error) {

            }
            return
        }
        let re = await tool.requestData('/admin/input_question_list_to_third', {
            questions: config.value.getRe()
        })
        if (re.status == 1) {
            tool.controlWarningForm(true, `${re.data.successList.length}个导入成功，${re.data.failList.length}个导入失败。`)
        } else {
            tool.controlWarningForm(true, re.content)
            return
        }
        let res = []
        for (let i = 0; i < re.data.successList.length; i++) {
            res.push({
                ...re.data.successList[i],
                success: true
            })
        }
        for (let i = 0; i < re.data.failList.length; i++) {
            res.push({
                ...re.data.failList[i]
            })
        }
        config.value.importStatus = res
        config.value.imported = true
    },
    importStatus: [],
    getImportStatus(thirdId, questionId) {
        for (let i = 0; i < config.value.importStatus.length; i++) {
            if (config.value.importStatus[i].thirdId == thirdId && config.value.importStatus[i].questionId == questionId) {
                return config.value.importStatus[i]
            }
        }
        return undefined
    }
})

onMounted(async () => {
    store.onChange('controlSetQuestionIndexForm', async () => {
        let data = store.getItem('controlSetQuestionIndexForm')
        config.value.callBack = data.callBack
        if (data.thirdList.length == 0) {
            tool.controlWarningForm(true, '未选择三级类别', () => { })
            await tool.wait(0.3)
            config.value.control(false)
            return
        }
        config.value.thirdList = data.thirdList
        for (let i = 0; i < config.value.thirdList.length; i++) {
            config.value.thirdList[i].children = {
                disorder: {
                    show: true,
                    list: JSON.parse(JSON.stringify(data.questionList))
                },
                order: {
                    show: true,
                    list: []
                },
                delete: {
                    show: false,
                    list: []
                }
            }
        }
        config.value.currentListIndex = 0
        config.value.importStatus = []
        config.value.imported = false
        config.value.control(data.op)
    })

    store.onChange('windowData', computeZoom)
})
</script>

<style scoped></style>