<template>
    <div v-if="config.show"
        style="width: 100%;height: 100%;position: fixed;left: 0;top: 0;z-index: 9000;background-color: rgba(0,0,0,0.3);transition-property: opacity, scale;transition-timing-function: cubic-bezier(0.1,0.9,0.2,1);transition-duration: .3s;"
        :style="{
            opacity: config.show > 1 ? 1 : 0,
            scale: config.show == 2 ? '1' : '1.1',
            zoom: tool.pageZoom()
        }">
        <div @click="config.control(false)" style="width: 100%;height: 100%;position: absolute;left: 0;top: 0;">

        </div>
        <div :style="{

        }">
            <div style="display: inline-block;width: 450px;max-height: 780px;height: calc(100vh - 40px);background-color: white;box-shadow:  0 10px 30px rgba(0,0,0,0.5);border-radius: 8px;position: absolute;transition-property:transform, scale, opacity;transition-duration: .3s;transition-timing-function: cubic-bezier(0.1,0.9,0.2,1);overflow: hidden;"
                :style="{
                    top: config.show == 2 || 1 ? `calc(50vh / ${tool.pageZoom()})` : store.getItem('mouseClickPosition').y.toString() + 'px',
                    left: config.show == 2 || 1 ? `calc(50vw / ${tool.pageZoom()})` : store.getItem('mouseClickPosition').x.toString() + 'px',
                    transform: 1 ? 'translate(-50%,-50%)' : 'translate(0%,0%)',
                    opacity: config.show == 2 ? 1 : 0
                }">
                <div @click="config.control(false)" class="button"
                    style="width: 50px;height: 50px;position: absolute;right: -60px;top: 0;border-radius: 8px;border: 1px solid white;">
                    <div
                        style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;color: white;">
                        <svg width="24" height="24" viewBox="0 0 48 48" fill="currentColor">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M23.886 20.957L37.32 7.522a1 1 0 011.414 0l1.414 1.414a1 1 0 010 1.415L26.714 23.786 40.149 37.22a1 1 0 010 1.414l-1.414 1.414a1 1 0 01-1.414 0L23.886 26.614 10.45 40.049a1 1 0 01-1.415 0l-1.414-1.414a1 1 0 010-1.414l13.435-13.435L7.622 10.35a1 1 0 010-1.415l1.414-1.414a1 1 0 011.415 0l13.435 13.435z"
                                fill="currentColor" />
                        </svg>
                    </div>
                </div>

                <div style="width: 100%;height: 100%;overflow-y: auto;padding: 24px 16px;transition: opacity .3s cubic-bezier(0.1,0.9,0.2,1);overflow-x: hidden;"
                    :style="{
                        opacity: config.loading ? 0 : 1
                    }">

                    <div v-if="config.loading == false && config.list.length == 0"
                        style="width: 100%;height: 60px;display: flex;align-items: center;justify-content: center;font-size: 16px;color: #2E4232;font-family: 微软雅黑">
                        列表为空
                    </div>
                    <div v-if="config.type == 1">
                        <div v-for="(item, index) in config.getListType1()"
                            style="width: 100%;height: 50px;margin-bottom: 14px;">
                            <div v-if="item.length > 0"
                                @click="handleShowDE('三级类别', 'practice', item[0].id); config.control(false)"
                                style="width: calc(50% - 7px);height: 50px;display: inline-block;border-radius: 3px;background-color: #f6f8f7;float: left;">
                                <div
                                    style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: left;padding-left: 8px;">
                                    <div style="display: inline-block;width: 45px;height: 45px;border-radius: 100px;background-color: #24c27d;color: white;font-family: almm;font-weight: 700;font-size: 22px;"
                                        :style="{
                                            backgroundColor: (item[0].icon == null) ? '#24c27d' : 'red'
                                        }">
                                        <div
                                            style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;">
                                            {{ item[0].icon == null ? (index * 2 + 1) : item[0].icon.slice(0, 1) }}
                                        </div>
                                    </div>
                                    <div
                                        style="display: inline-block;line-height: 45px;width: 150px;padding-left: 3px;font-family: 微软雅黑;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;height: 45px">
                                        {{ item[0].title }}
                                    </div>
                                </div>
                            </div>
                            <div v-if="item.length > 1"
                                @click="handleShowDE('三级类别', 'practice', item[1].id); config.control(false)"
                                style="width: calc(50% - 7px);height: 50px;display: inline-block;border-radius: 3px;background-color: #f6f8f7;float: right;">
                                <div
                                    style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: left;padding-left: 8px;">
                                    <div style="display: inline-block;width: 45px;height: 45px;border-radius: 100px;background-color: #24c27d;color: white;font-family: almm;font-weight: 700;font-size: 22px;"
                                        :style="{
                                            backgroundColor: (item[1].icon == null) ? '#24c27d' : 'red'
                                        }">
                                        <div
                                            style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;">
                                            {{ item[1].icon == null ? (index * 2 + 2) : item[1].icon.slice(0, 1) }}
                                        </div>
                                    </div>
                                    <div
                                        style="display: inline-block;line-height: 45px;width: 150px;padding-left: 3px;font-family: 微软雅黑;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;height: 45px">
                                        {{ item[1].title }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="config.type == 2"
                        style="width: 100%;display: flex;align-items: flex-start;justify-content: center;flex-direction: column;">
                        <div v-for="(item, index) in config.list"
                            style="width: 100%;display: flex;align-items: center;justify-content: center;height: 60px;">
                            <div style="display: inline-block;width: 140px;">
                                <div
                                    style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;flex-direction: column;">
                                    <div style="display: inline-block;width: 35px;height: 35px;border-radius: 100px;background-color: #24c27d;"
                                        :style="{
                                            backgroundColor: (item.icon == null || item.icon == '') ? '#24c27d' : 'red'
                                        }">
                                        <div
                                            style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;font-weight: bold;font-family: 微软雅黑;color: white;font-size: 24px;">
                                            {{ (item.icon == null || item.icon == '') ? (index + 1) :
                                                item.icon
                                            }}
                                        </div>
                                    </div>
                                    <div
                                        style="color: #2E4232;font-family: 微软雅黑;font-size: 12px;display: inline-block;word-break: keep-all;">
                                        {{ item.title }}
                                    </div>
                                </div>
                            </div>
                            <div style="display: inline-block;height: 100%;width: 100%;">
                                <div
                                    style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;">
                                    <YButton @click="handleShowDE('三级类别', 'practice', item.id); config.control(false)"
                                        color="white" background-color="rgb(36, 194, 125)" mask-color="white">先练习
                                    </YButton>
                                </div>
                            </div>
                            <div style="display: inline-block;height: 100%;width: 100%;">
                                <div
                                    style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;">
                                    <YButton @click="handleShowDE('三级类别', 'exam', item.id); config.control(false)"
                                        color="white" background-color="rgb(36, 194, 125)" mask-color="white">后测试
                                    </YButton>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        style="width: calc(100% + 32px);border-top: 10px solid rgb(242,242,242);font-family: 微软雅黑;color: #2E4232;transform: translateX(-16px);padding: 16px 16px;">
                        <div style="color: red;font-family: 微软雅黑;line-height: 1.5;">
                            温馨提示：
                        </div>
                        <div v-html="config.tip">

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script setup>
import { ref, reactive, defineProps, computed, watch, onMounted, onBeforeUnmount } from 'vue'
import store from '../module/store'
import tool from '../module/tool'
import YButton from './yButton.vue';
import router from '@/router';

const handleShowDE = async (listType, exeType, id) => {
    if (store.getItem('userData') == undefined) {
        tool.controlWarningForm(true, '您还没有登录，请先登录！', () => {
            router.push('/login')

        })
        return
    }
    if (id == -1) {
        try {
            let questions = await tool.getQuestionList({
                listType: '地方专题',
                exeType: exeType,
                thirdId: id,
                sequentially: 0,
                vehicleId: config.value.vid,
                subjectId: config.value.sid
            })
            if (questions.length == 0) {
                throw new Error('题目列表为空')
                return
            }
            tool.handleShowDE('地方专题', exeType, id, 0, questions, config.value.vid, config.value.sid)
        } catch (error) {
            tool.controlWarningForm(true, error.toString())
        }
        return
    }
    let re = []
    try {
        re = await tool.getQuestionList({
            listType: listType,
            exeType: exeType,
            thirdId: id,
            sequentially: 0
        })

        if (re.length == 0) {
            tool.controlWarningForm(true, '题目列表为空')
        } else {
            tool.handleShowDE(listType, exeType, id, exeType == 'exam' ? 1 : 0, exeType == 'exam' ? tool.disorderArr(re) : re)
        }
    } catch (error) {
        tool.controlWarningForm(true, error.toString())
    }
}

const config = ref({
    show: 0,
    async control(op) {
        if (op) {
            this.show = 1
            await tool.wait(0.001)
            this.show = 2
        } else {
            this.show = 1
            await tool.wait(0.3)
            this.show = 0
        }
    },
    list: [],
    type: undefined,
    menu1Id: undefined,
    menu2Id: undefined,
    secondId: undefined,
    loading: false,
    vid: undefined,
    sid: undefined,
    tip: '',
    async get() {
        this.loading = true
        let re = await tool.requestData(tool.getHomeAPIUserType() + '/get_third_list', {
            secondId: this.secondId,
        })
        this.loading = false
        if (re.status) {
            this.list = re.data
        } else {
            tool.controlWarningForm(true, re.content)
        }
    },
    getListType1() {
        let re = [[]]
        for (let i = 0; i < this.list.length; i++) {
            if (re[re.length - 1].length < 2) {
                re[re.length - 1].push(this.list[i])
            } else {
                re.push([this.list[i]])
            }
        }
        if (re[0].length == 0) re = []
        return re
    },
})

onMounted(async () => {
    store.onChange('controlMenu3Form', async () => {
        let data = store.getItem('controlMenu3Form')
        // 11 // 11_2 console.log(123, data)
        if (data.op) {
            config.value.type = data.type
            config.value.secondId = data.secondId
            config.value.tip = data.tip == null ? '' : data.tip
            config.value.vid = data.vid
            config.value.sid = data.sid
        }
        // 11_2 console.log(config.value, data)
        config.value.control(data.op)
        if (data.op) {
            await config.value.get()
        }
    })
})
</script>

<style scoped></style>