<template>
    <div v-if="config.show != 0"
        style="position: fixed;left: 0;top:0;z-index: 9990;background-color: rgba(0,0,0,0.3);width: 100vw;height: 100vh;transition: background-color .3s cubic-bezier(0.1,0.9,0.2,1);display: flex;align-items: center;justify-content: center;"
        :style="{
            backgroundColor: config.show <= 1 ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,0.3)'
        }">
        <div :style="{
            opacity: config.show <= 1 ? '0' : '1',
            scale: config.show <= 1 ? '1.1' : '1',
            zoom: zoom
        }"
            style="padding: 20px 20px;position: relative;display: inline-block;background-color: rgba(255,255,255,1);border-radius: 10px;box-shadow: 0 10px 30px rgba(0,0,0,0.5);max-width: calc(100% - 40px);max-height: calc(100vh - 40px);width: 800px;height: 600px;transition-property: opacity, scale;transition-duration: .3s; transition-timing-function: cubic-bezier(0.1,0.9,0.2,1);backdrop-filter: blur(20px);">
            <div style="width: 100%;height: 24px;">
                <div style="line-height: 24px;display: inline-block;font-size: 18px;font-family: 微软雅黑;color: #2e4232;">
                    选择三级类别
                </div>
                <div style="display: inline-block;height: 100%;float: right;">
                    <div style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: right;">
                        <svg @click="() => {
                            config.control(false)
                        }" style="float: right;" width="24" height="24" viewBox="0 0 48 48" fill="currentColor">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M23.886 20.957L37.32 7.522a1 1 0 011.414 0l1.414 1.414a1 1 0 010 1.415L26.714 23.786 40.149 37.22a1 1 0 010 1.414l-1.414 1.414a1 1 0 01-1.414 0L23.886 26.614 10.45 40.049a1 1 0 01-1.415 0l-1.414-1.414a1 1 0 010-1.414l13.435-13.435L7.622 10.35a1 1 0 010-1.415l1.414-1.414a1 1 0 011.415 0l13.435 13.435z"
                                fill="currentColor" />
                        </svg>
                    </div>
                </div>
            </div>
            <div
                style="width: 100%;height: calc(100% - 24px - 16px);margin-top: 16px;position: relative;display: flex;align-items: center;justify-content: center;gap: 8px;">
                <div
                    style="width: 100%;height: 100%;display: flex;flex-direction: column;align-items: center;justify-content: center;gap: 8px;">
                    <div style="font-size: 14px;color: gray;font-family: 微软雅黑;width:100%">
                        {{ config.getTitle() }}
                    </div>
                    <div
                        style="width: 100%;height: 100%;border-radius: 8px;background-color: #f6f8f7;overflow-y: auto;padding: 16px;display: flex;align-items: flex-start;justify-content: left;gap: 8px;flex-direction: column;">

                        <div v-if="tree.data.length == 0"
                            style="width: 100%;font-size: 14px;color: gray;font-family: 微软雅黑;">
                            车型列表为空
                        </div>

                        <div v-for="(item1, index1) in tree.data" style="width: 100%;">
                            <yButton style="width: 100%;" background-color="rgba(0,0,0,0)"
                                @click="item1.selected = !item1.selected">
                                <div
                                    style="width: 100%;font-family: 微软雅黑;font-size: 14px;color: #2e4232;display: flex;align-items: center;justify-content: left;gap: 8px;">
                                    <svg :style="{
                                        rotate: item1.selected ? '90deg' : ''
                                    }" width="16" height="16" viewBox="0 0 48 48" fill="currentColor">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M32.556 24L16.293 7.737a1 1 0 010-1.415l1.414-1.414a1 1 0 011.414 0L36.8 22.586a2 2 0 010 2.828L19.121 43.092a1 1 0 01-1.414 0l-1.414-1.414a1 1 0 010-1.414L32.556 24z"
                                            fill="currentColor" />
                                    </svg>
                                    <span style="font-size: 16px;color: #2e4232;font-family: 微软雅黑;">{{ item1.title
                                        }}</span>
                                </div>
                            </yButton>
                            <div v-if="item1.selected && item1.children != undefined"
                                style="width: 100%;padding-left: 16px;display: flex;align-items: flex-start;justify-content: left;gap: 8px;flex-direction: column;margin-top: 8px;border-left: 1px solid #2e4232;">

                                <div v-if="item1.children.length == 0"
                                    style="width: 100%;font-size: 14px;color: gray;font-family: 微软雅黑;">
                                    科目列表为空
                                </div>

                                <div v-for="(item2, index2) in item1.children" style="width: 100%;">
                                    <yButton style="width: 100%;" background-color="rgba(0,0,0,0)"
                                        @click="tree.secondClick(item2, index1, index2)">
                                        <div
                                            style="width: 100%;font-family: 微软雅黑;font-size: 14px;color: #2e4232;display: flex;align-items: center;justify-content: left;gap: 8px;">
                                            <svg :style="{
                                                rotate: item2.selected ? '90deg' : ''
                                            }" width="16" height="16" viewBox="0 0 48 48" fill="currentColor">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M32.556 24L16.293 7.737a1 1 0 010-1.415l1.414-1.414a1 1 0 011.414 0L36.8 22.586a2 2 0 010 2.828L19.121 43.092a1 1 0 01-1.414 0l-1.414-1.414a1 1 0 010-1.414L32.556 24z"
                                                    fill="currentColor" />
                                            </svg>
                                            <span style="font-size: 16px;color: #2e4232;font-family: 微软雅黑;">{{
                                                item2.title
                                            }}</span>
                                        </div>
                                    </yButton>
                                    <div v-if="item2.selected && item2.children != undefined"
                                        style="width: 100%;padding-left: 16px;display: flex;align-items: flex-start;justify-content: left;gap: 8px;flex-direction: column;margin-top: 8px;border-left: 1px solid #2e4232;">

                                        <div v-if="item2.children.length == 0"
                                            style="width: 100%;font-size: 14px;color: gray;font-family: 微软雅黑;">
                                            二级类别列表为空
                                        </div>

                                        <div v-for="(item3, index3) in item2.children" style="width: 100%;">
                                            <yButton style="width: 100%;" background-color="rgba(0,0,0,0)"
                                                @click="tree.thirdClick(item3, index1, index2, index3)">
                                                <div
                                                    style="width: 100%;font-family: 微软雅黑;font-size: 14px;color: #2e4232;display: flex;align-items: center;justify-content: left;gap: 8px;">
                                                    <svg :style="{
                                                        rotate: item3.selected ? '90deg' : ''
                                                    }" width="16" height="16" viewBox="0 0 48 48" fill="currentColor">
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                            d="M32.556 24L16.293 7.737a1 1 0 010-1.415l1.414-1.414a1 1 0 011.414 0L36.8 22.586a2 2 0 010 2.828L19.121 43.092a1 1 0 01-1.414 0l-1.414-1.414a1 1 0 010-1.414L32.556 24z"
                                                            fill="currentColor" />
                                                    </svg>
                                                    <span style="font-size: 16px;color: #2e4232;font-family: 微软雅黑;">{{
                                                        item3.title
                                                        }}</span>
                                                </div>
                                            </yButton>
                                            <div v-if="item3.selected && item3.children != undefined"
                                                style="width: 100%;padding-left: 16px;display: flex;align-items: flex-start;justify-content: left;gap: 8px;flex-direction: column;margin-top: 8px;border-left: 1px solid #2e4232;">

                                                <div v-if="item3.children.length == 0"
                                                    style="width: 100%;font-size: 14px;color: gray;font-family: 微软雅黑;">
                                                    三级类别列表为空
                                                </div>

                                                <div style="width: 100%;height: 40px;display: flex;align-items: center;justify-content: left;"
                                                    v-for="(item4, index4) in item3.children">
                                                    <div
                                                        style="width: 100%;font-family: 微软雅黑;font-size: 14px;color: #2e4232;display: flex;align-items: center;justify-content: left;">
                                                        <div
                                                            style="display: flex;align-items: center;justify-content: left;height: 100%;width: 100%;gap: 8px;">
                                                            <div style="width: 25px;height: 25px;border-radius: 100px;display: inline-block;"
                                                                :style="{
                                                                    backgroundColor: item4.icon ? 'red' : '#24c27d'
                                                                }">
                                                                <div
                                                                    style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;font-family: 微软雅黑 ;font-size: 16px;color: white;font-weight: bold;">
                                                                    {{ item4.icon ? item4.icon.slice(0, 1) : (index4 +
                                                                        1) }}
                                                                </div>
                                                            </div>
                                                            <span
                                                                style="font-size: 16px;color: #2e4232;font-family: 微软雅黑;display: inline-block;white-space: nowrap;word-break: keep-all;overflow: hidden;text-overflow: ellipsis;width: 220px;">{{
                                                                    item4.title
                                                                }}</span>
                                                        </div>
                                                        <div
                                                            style="width: 80px;height: 100%;display: flex;align-items: center;justify-content: right;gap: 8px;">
                                                            <yButton color="#24c27d" background-color="rgba(0,0,0,0)"
                                                                @click="selected.add(tree.getSelectObject(item1, item2, item3, item4, index4))"
                                                                v-if="selected.isSelected(tree.getSelectObject(item1, item2, item3, item4, index4)) == false"
                                                                padding="0">选择</yButton>
                                                            <yButton color="red" background-color="rgba(0,0,0,0)"
                                                                @click="selected.delete(tree.getSelectObject(item1, item2, item3, item4, index4))"
                                                                v-if="selected.isSelected(tree.getSelectObject(item1, item2, item3, item4, index4))"
                                                                padding="0">取消选择</yButton>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
                <div style="width: 540px;height: 100%;">
                    <div
                        style="width: 100%;height: calc(100% - 45px - 8px);display: flex;flex-direction: column;align-items: center;justify-content: left;gap: 8px;margin-bottom: 8px;">
                        <div style="font-size: 14px;color: gray;font-family: 微软雅黑;width:100%">
                            已选{{ selected.data.length ? `(${selected.data.length}个)` : '' }}
                        </div>
                        <div
                            style="width: 100%;height: 100%;border-radius: 8px;background-color: #f6f8f7;overflow: hidden;">

                            <div style="width: 100%;height: 100%;overflow-y: auto;">

                                <div
                                    style="display: flex;flex-direction: column;align-items: flex-start;justify-content: left;gap: 22px;padding: 16px;">

                                    <div v-if="selected.data.length == 0"
                                        style="width: 100%;font-family: 微软雅黑;color: gray;font-size: 14px;">
                                        未选择任何三级类别
                                    </div>

                                    <div v-for="(item, index) in selected.data"
                                        style="width: 100%;display: flex;align-items: center;justify-content: center;gap: 3px;">
                                        <div
                                            style="width: 100%;height: 100%;font-family: 微软雅黑;font-size: 16px;color: #2e4232;">
                                            {{ item.title }}
                                        </div>
                                        <div @click="selected.delete(item)"
                                            style="height: 100%;width: 40px;display: flex;align-items: center;justify-content: right;">
                                            <yButton font-size="14px" background-color="rgba(0,0,0,0)" padding="0"
                                                color="red">
                                                删除</yButton>
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>
                    <div style="width: 100%;display: flex;align-items: center;justify-content: center;gap: 8px;">
                        <yButton style="width: 100%;height: 45px;" border-radius="8px" font-size="16px"
                            @click="config.control(false)">取消</yButton>
                        <yButton v-if="selected.data.length" @click="handleSubmit" style="width: 100%;height: 45px;"
                            background-color="#24c27d" color="white" mask-color="white" border-radius="8px"
                            font-size="16px">确定</yButton>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, reactive, defineProps, computed, watch, onMounted, onBeforeUnmount } from 'vue'
import store from '../module/store'
import tool from '../module/tool'
import yButton from './yButton.vue';

const computeZoom = () => {
    let data = store.getItem('windowData')
    if (data.width < 1200) {
        zoom.value = data.width / 1200
    } else {
        zoom.value = 1
    }
}
const zoom = ref(1)

const tree = ref({
    data: [],
    callBack: () => { },
    async secondClick(item2, index1, index2) {
        if (item2.selected) {
            item2.selected = undefined
            return
        }
        let id1 = this.data[index1].id
        let id2 = this.data[index1].children[index2].id
        // 11 // 11_2 console.log(id1, id2)
        if (item2.children == undefined) {
            let re = await tool.requestData('/' + store.getItem('userType') + '/get_first_list', {
                "vehicleId": id1,
                "subjectId": id2
            })
            if (re.status == 1) {

            } else {
                tool.controlWarningForm(true, re.content)
            }

            let re2 = await tool.requestData('/' + store.getItem('userType') + '/get_second_list', {
                firstId: re.data.id
            })
            if (re2.status == 1) {
                item2.children = re2.data
                item2.selected = true
            } else {
                tool.controlWarningForm(true, re2.content)
            }
        } else {
            item2.selected = true
        }
    },
    async thirdClick(item3, index1, index2, index3) {
        if (item3.selected) {
            item3.selected = undefined
            return
        }
        if (item3.children == undefined) {
            let id = tree.value.data[index1].children[index2].children[index3].id
            let re = await tool.requestData('/' + store.getItem('userType') + '/get_third_list', {
                secondId: id
            })
            if (re.status == 1) {
                item3.children = re.data
                item3.selected = true
            } else {
                tool.controlWarningForm(true, re.content)
            }
        } else {
            item3.selected = true
        }
    },
    async init() {
        tree.value.data = []
        let re = await tool.requestData('/' + store.getItem('userType') + '/get_vehicle_list', {}, 'get')
        if (re.status == 1) {
            tree.value.data = re.data

            let re1 = await tool.requestData('/' + store.getItem('userType') + '/get_subject_list', {}, 'get')
            if (re1.status == 1) {
                for (let i = 0; i < tree.value.data.length; i++) {
                    tree.value.data[i].children = JSON.parse(JSON.stringify(re1.data))
                }
            } else {
                tool.controlWarningForm(true, '获取科目列表失败', () => {
                    config.value.control(false)
                })
            }
        } else {
            tool.controlWarningForm(true, '获取车型列表失败', () => {
                config.value.control(false)
            })
        }
    },
    getSelectObject(item1, item2, item3, item4, index4) {
        return {
            title: item1.title + ' > ' + item2.title + ' > ' + item3.title + ' > ' + item4.title,
            location: item1.title + ' > ' + item2.title + ' > ' + item3.title,
            thirdId: item4.id,
            mark: `${item1.id}-${item2.id}-${item3.id}-${item4.id}`,
            name: item4.title,
            icon: item4.icon ? item4.icon.slice(0, 1) : (index4 + 1).toString(),
            iconSetted: item4.icon ? true : false
        }
    }
})

const selected = ref({
    data: [],
    isSelected(so) {
        for (let i = 0; i < selected.value.data.length; i++) {
            if (selected.value.data[i].mark == so.mark) {
                return true
            }
        }
        return false
    },
    add(so) {
        if (selected.value.data.length == 1 && config.value.type == 1) {
            tool.controlWarningForm(true, '只能选择一个三级类别')
            return
        }
        selected.value.data.push(so)
    },
    delete(so) {
        let re = []
        for (let i = 0; i < selected.value.data.length; i++) {
            if (selected.value.data[i].mark == so.mark) continue
            re.push(selected.value.data[i])
        }
        selected.value.data = re
    }
})

const handleSubmit = () => {
    config.value.callBack(JSON.parse(JSON.stringify(selected.value.data)))
    config.value.control(false)
}

const config = ref({
    show: 0,
    type: 1,
    callBack: () => { },
    getTitle() {
        if (this.type == 1) {
            return '只能选择一个三级类别'
        }
        if (this.type == 2) {
            return '可以选择多个三级类别'
        }
    },
    async control(op) {
        if (op) {
            computeZoom()
            tree.value.init()
            this.show = 1
            await tool.wait(0.001)
            this.show = 2
        } else {
            this.show = 1
            await tool.wait(0.2)
            this.show = 0
        }
    },
})

onMounted(async () => {
    store.onChange('controlThirdListForm', () => {
        let data = store.getItem('controlThirdListForm')
        config.value.type = data.type
        config.value.callBack = data.callBack
        selected.value.data = []
        config.value.control(data.op)
    })

    store.onChange('windowData', computeZoom)
})
</script>

<style scoped></style>