<template>
    <div v-if="config.show != 0"
        style="position: fixed;left: 0;top:0;z-index: 10000;background-color: rgba(0,0,0,0.3);width: 100vw;height: 100vh;transition: background-color .3s cubic-bezier(0.1,0.9,0.2,1);display: flex;align-items: center;justify-content: center;"
        :style="{
            backgroundColor: config.show <= 1 ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,0.3)'
        }">
        <div :style="{
            opacity: config.show <= 1 ? '0' : '1',
            scale: config.show <= 1 ? '1.1' : '1',
            zoom: tool.pageZoom()
        }"
            style="padding: 20px 20px;position: relative;display: inline-block;background-color: rgba(255,255,255,1);border-radius: 10px;box-shadow: 0 10px 30px rgba(0,0,0,0.5);max-width: calc(100vw - 40px);max-height: calc(100vh - 40px);width: 600px;height: auto;overflow-y: auto;transition-property: opacity, scale;transition-duration: .3s; transition-timing-function: cubic-bezier(0.1,0.9,0.2,1);backdrop-filter: blur(20px);">
            <div style="width: 100%;height: 24px;">
                <div style="line-height: 24px;display: inline-block;font-size: 18px;font-family: 微软雅黑;color: #2e4232;">
                    {{ config.title }}</div>
                <div style="display: inline-block;height: 100%;float: right;">
                    <div style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: right;">
                        <svg @click="() => {
                            config.control(false)
                        }" v-if="config.callBack != undefined && config.showCancle == true" style="float: right;"
                            width="24" height="24" viewBox="0 0 48 48" fill="currentColor">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M23.886 20.957L37.32 7.522a1 1 0 011.414 0l1.414 1.414a1 1 0 010 1.415L26.714 23.786 40.149 37.22a1 1 0 010 1.414l-1.414 1.414a1 1 0 01-1.414 0L23.886 26.614 10.45 40.049a1 1 0 01-1.415 0l-1.414-1.414a1 1 0 010-1.414l13.435-13.435L7.622 10.35a1 1 0 010-1.415l1.414-1.414a1 1 0 011.415 0l13.435 13.435z"
                                fill="currentColor" />
                        </svg>
                    </div>
                </div>
            </div>
            <div
                style="width: 100%;display: flex;align-items: center;justify-content: left;gap: 8px;flex-direction: column;margin-top: 16px;">
                <yButton color="#2e4232" style="width: 100%;" @click="config.handleButtonClick(index)"
                    v-for="(item, index) in config.operationList">{{ item.name }}</yButton>
            </div>
            <div
                style="width: 100%;margin-top: 36px;display: flex;align-items: center;justify-content: center;gap: 8px;">
                <yButton color="white" background-color="#24c27d" mask-color="white" class="activeScale" @click="() => {
                    config.control(false)
                }" style="width: 100%;">取消</yButton>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, reactive, defineProps, computed, watch, onMounted, onBeforeUnmount } from 'vue'
import store from '../module/store'
import tool from '../module/tool'
import yButton from './yButton.vue';

const config = ref({
    show: 0,
    title: '',
    operationList: [],
    async control(op, title = '', operationList = []) {
        if (op) {
            this.title = title
            this.operationList = operationList
            this.show = 1
            await tool.wait(0.001)
            this.show = 2
        } else {
            this.show = 1
            await tool.wait(0.2)
            this.show = 0
        }
    },
    handleButtonClick(index) {
        try {
            this.control(false)
            this.operationList[index].callBack()
        } catch (error) {
            // 11 // 11_2 console.log(error)
        }
    }
})

onMounted(async () => {
    store.onChange('controlMenuForm', () => {
        let data = store.getItem('controlMenuForm')
        config.value.control(data.op, data.title, data.operationList)
    })
})
</script>

<style scoped></style>